import { Box } from "@chakra-ui/react"
import React from "react"
import { VomuxTextSubTitle } from "./vomux_text"

export const Agreements = () => {
  return (
    <Box display="flex" textAlign="center" mt="1rem" pb="1rem">
      <VomuxTextSubTitle fontSize=".75rem">
        <a target="_blank" href="/privacy">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a target="_blank" href="/terms">
          Terms of Service
        </a>{" "}
        apply.
      </VomuxTextSubTitle>
    </Box>
  )
}
