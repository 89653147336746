import { Box } from "@chakra-ui/react"
import React from "react"
import Helmet from "react-helmet"
import { Login } from "../components/login/Login"
import Seo from "../components/seo"
import { useDelayedAnalyticsEventOnLand } from "../hooks/analytics"
import { AnalyticsEvents } from "../services/types"

const LoginPage = () => {
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.login_form_landed)
  return (
    <Box>
      <Seo title="Login" />
      <Login />
    </Box>
  )
}

export default LoginPage
