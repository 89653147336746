import { Box, Image } from "@chakra-ui/react"
import React from "react"
import SignInBackground from "../../images/sign_in_background.svg"
import SignInBackgroundDots from "../../images/sign_in_background_dots.svg"
import SignInBackgroundDotsLight from "../../images/sign_in_background_dots_light.svg"
import VomuxLogo from "../../images/vomux_logo.svg"
import SignInBackgroundMobile from "../../images/sign_in_background_mobile.svg"
import LoginForm from "./login_form"

export const Login: React.FC = props => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundPosition={{ base: "center", md: "inherit" }}
      backgroundImage={{ base: SignInBackgroundMobile, md: SignInBackground }}
      backgroundRepeat="no-repeat"
    >
      <Image
        position="absolute"
        top="26rem"
        left={{ base: "2rem", md: "10rem" }}
        src={SignInBackgroundDots}
      />
      <Image
        position="absolute"
        top="8rem"
        right="1rem"
        src={SignInBackgroundDotsLight}
      />
      <Image src={VomuxLogo} position="fixed" top="3rem" left="3rem" />
      <LoginForm />
    </Box>
  )
}
