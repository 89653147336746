import { Box, Input } from "@chakra-ui/react"
import React, { useContext, useEffect, useState } from "react"
import { VomuxButton } from "../shared/vomux_button"
import {
  VomuxTextAttention,
  VomuxTextStandard,
  VomuxTextTitle,
} from "../shared/vomux_text"
import SignInBanner from "../../images/sign_in_comapnies_banner.svg"
import "../../index.css"
import { navigate } from "gatsby"
import { ApiContext, CompanyContext, UserContext } from "../Root"
import { ApiClient } from "../../services/api"
import validator from "email-validator"
import {
  sendAmplitudeEvent,
  setAmplitudeUserId,
} from "../../services/amplitude"
import { Agreements } from "../shared/agreements"
import { AnalyticsEvents } from "../../services/types"

const LoginForm: React.FC = props => {
  const api = useContext(ApiContext)
  const user = useContext(UserContext)
  const company = useContext(CompanyContext)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [emailError, setEmailError] = useState(false)

  const handleLogin = async () => {
    sendAmplitudeEvent(AnalyticsEvents.login_form_login_attempted, {
      email,
    })
    const loginRes = await api.client.login({
      email,
      account_password: password,
    })
    if (!validator.validate(email)) {
      setEmailError(true)
      return
    }
    if (!loginRes || !loginRes.token) {
      sendAmplitudeEvent(AnalyticsEvents.login_form_login_failed, {
        email,
      })
      alert("issue logging in")
      return
    }
    const newClient = new ApiClient(loginRes.token)
    api.setApi(newClient)
    sendAmplitudeEvent(AnalyticsEvents.login_form_login_succeeded, {
      email,
    })
    const companiesRes = await newClient.getCompaniesForAccount()
    if (companiesRes.length > 0) {
      company.setCompany(companiesRes[0])
    }
    const accountRes = await newClient.getAccount()
    if (!accountRes) {
      alert("There was an issue fetching your account details.")
      return
    }
    setAmplitudeUserId(accountRes.account_id)
    user.setUser(accountRes)
  }

  useEffect(() => {
    if (user.data?.account_id) {
      sendAmplitudeEvent(AnalyticsEvents.login_form_login_redirected, {
        account_id: user.data?.account_id,
        location: "/home",
      })
      navigate("/home")
    }
  }, [user.data?.account_id])

  return (
    <Box
      zIndex="2"
      height="70%"
      width={{ base: "80%", md: "60%", lg: "40%" }}
      borderRadius="10px"
      backgroundColor="white"
      boxShadow="0px 10px 8px #bbb"
      display="flex"
      justifyContent="center"
      marginTop={{ base: "4rem", md: "0" }}
    >
      <Box
        width="80%"
        alignItems="center"
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        marginTop="2rem"
      >
        <VomuxTextTitle marginBottom="2rem">Login</VomuxTextTitle>
        <Input
          padding=".5rem"
          placeholder="Email"
          borderRadius="10px"
          width="100%"
          value={email}
          onChange={e => {
            setEmailError(false)
            setEmail(e.target.value)
          }}
        ></Input>
        <Input
          padding=".5rem"
          placeholder="Password"
          borderRadius="10px"
          width="100%"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        ></Input>
        <VomuxTextStandard
          fontSize={"1rem"}
          color="red"
          display={emailError ? "flex" : "none"}
        >
          Please enter a valid email.
        </VomuxTextStandard>
        <VomuxButton
          padding=".5rem"
          borderRadius="10px"
          marginBottom="1rem"
          w="100%"
          onClick={handleLogin}
        >
          Login
        </VomuxButton>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          flexDirection="row"
        >
          <VomuxTextStandard fontSize="1rem">
            Don't have an account?
          </VomuxTextStandard>
          <VomuxTextAttention
            onClick={() => {
              navigate("/signup")
            }}
            cursor="pointer"
            fontSize="1rem"
          >
            Sign Up
          </VomuxTextAttention>
        </Box>
        <Agreements />
      </Box>
    </Box>
  )
}

export default LoginForm
